import "./map.css";
const Map = () => {
  return (
    <>
      <div className="container-fluid" id="map-count">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d53155.498172110165!2d73.031956917635!3d33.625572635474946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1srawalpindi%20Office%2309%2C%20National%20Business%20Center%2C%20Murree%20Rd%2C%20Shamsabad%2C%20Rawalpindi!5e0!3m2!1sen!2s!4v1675406693106!5m2!1sen!2s"
                  id="map"
                  title="The Revolution Technologies"
        ></iframe>
      </div>
    </>
  );
};
export default Map;
